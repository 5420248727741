import { template as template_8ad80f3ecc49485eb49fe301eeff3434 } from "@ember/template-compiler";
const FKLabel = template_8ad80f3ecc49485eb49fe301eeff3434(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
