import { template as template_2577ffa5788d4cb098c0973438ba1282 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2577ffa5788d4cb098c0973438ba1282(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
